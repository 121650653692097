<!-- 基础按钮 -->
<template>
  <button
    class="btn"
    :class="[size, type]"
    @click="handleClick"
  >
    <i :class="'el-icon-' + icon"></i>
    <slot></slot>
  </button>
</template>

<script>
import { reactive, computed, toRefs, onBeforeMount, onMounted, defineComponent } from "vue";
export default defineComponent({
  name: "",
  props: {
    // 按钮类型 default
    type: {
      type: String,
      required: true,
      default: "",
    },
    // 按钮icon --- elementUI
    icon: {
      type: String,
      required: false,
      default: "",
    },
    // 按钮大小 small、large、huge
    size:{
      type: String,
      required: false,
      default: "normal"
    }
  },
  setup(props, { emit }) {
    const data = reactive({
      type: props.type,
      size: props.size,
      icon: props.icon,
      handleClickL:()=>{
        emit('click')
      }
    });
    onBeforeMount(() => {});
    onMounted(() => {});
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
});
</script>
<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/button.scss";
@import "@/styles/mixin.scss";
</style>

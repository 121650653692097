<!-- 基础表格组件 -->
<template>
  <div>
    <el-table
      :data="tableData"
      class="staff-table"
      style="width: 100%"
      highlight-current-row
      :height="tableHeight"
      v-loading="loading"
    >
      <!-- 循环遍历表头展示数据 -->
      <el-table-column
        v-for="(th, index) in tableColumns"
        show-overflow-tooltip
        header-align="center"
        :prop="th.prop"
        :label="th.label"
        :key="index"
        :width="th.width || ''"
        :align="th.align || 'center'"
      >
        <template #default="scope">
          <!-- <slot v-if="item.slotname" :scope="scope" :name="item.slotname" />
          <div v-else>
            <span>{{ scope.row[item.value] }}</span>
          </div> -->
          <!-- 操作按钮 -->
          <div v-if="th.operation">
            <el-button
              v-for="(o, key) in th.operation"
              :key="key"
              class="table-btn"
              :icon="o.icon"
              @click="handleClickBtn(o.clickFun, scope.row)"
              type="text"
              size="mini"
            >
              {{ o.name }}
            </el-button>
            <!-- <el-popover
            v-if="th.describe"
            popper-class="popover-el-class"
            placement="bottom"
            width="200"
            trigger="hover"
            :content="scope.row[th.prop]"
          >
            <span class="describe-wrap" style="-webkit-box-orient:vertical">
              {{ scope.row[th.prop] }}
            </span>
          </el-popover> -->
          </div>
          <!-- 点击跳转页面 -->
          <div v-else-if="th.router">
            <router-link
              :to="{
                path: th.router.path,
                query: { expertFields: scope.row['fieldName'] },
              }"
              >{{ scope.row[th.prop] }}</router-link
            >
          </div>
          <div v-else>
            <!-- 鼠标滑过显示气泡框 -->
            <el-popover
              v-if="th.describe"
              popper-class="popover-el-class"
              placement="top-start"
              width="200"
              trigger="hover"
              :content="scope.row[th.prop]"
            >
              <!-- <span class="describe-wrap" style="-webkit-box-orient:vertical">
                {{ scope.row[th.prop] }}
              </span> -->
              <template #reference>
                <el-button
                  icon="el-icon-view"
                  class="app-view"
                  type="text"
                  circle
                >
                </el-button>
              </template>
            </el-popover>
            <!-- 纯展示数据 -->
            <span v-else-if="!th.formatData">{{ scope.row[th.prop] }}</span>
            <!-- 需要格式化的数据-->
            <span v-else>{{ scope.row[th.prop] }}</span>
          </div>
        </template>
      </el-table-column>
      <!-- 暂无数据插槽 -->
      <template v-slot:empty>
        <slot name="empty"></slot>
      </template>
    </el-table>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeMount, onMounted, watch } from "vue";
export default {
  name: "",
  props: {
    tableHeight: {
      type: Number,
      default: 500,
    },
    // 表头属性
    tableColumns: {
      type: Array,
      default: () => [],
    },
    // 表格内容数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // loading加载
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    watch(
      () => {
        return props.tableData;
      },
      (value) => {
        console.log(
          "BaseTable watch tableData:",
          JSON.parse(JSON.stringify(props.tableData))
        );
        state.tableData = value;
      }
    );
    watch(
      () => {
        return props.tableColumns;
      },
      (value) => {
        console.log(
          "BaseTable watch tableColumns:",
          JSON.parse(JSON.stringify(value))
        );
        state.tableColumns = value;
      }
    );
    const state = reactive({
      tableData: [],
      tableColumns: [],
      handleClickBtn(item, rowData) {
        emit(item, rowData);
      },
    });
    onBeforeMount(() => {});
    onMounted(() => {});
    const refData = toRefs(state);
    return {
      ...refData,
    };
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/element-ui.scss";

// 操作按钮
.table-btn {
  width: 81px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #3d8fff;
  border-radius: 4px;
  color: #3d8fff;
  text-align: center;
  padding: 0;
  min-height: 37px;
  line-height: 0;
  font-size: 16px;
  font-family: SourceRegular;
  font-weight: 400;
  transition: all 0.2s linear;
  &:hover {
    color: #fff;
    background-color: #3d8fff;
  }
}
</style>

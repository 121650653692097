<!-- modal框 -->
<template>
  <transition name="slide el-fade-in-linear">
    <div class="modal" v-show="showModal">
      <div class="mask"></div>
      <div class="modal-dialog">
        <div class="modal-header">
          <span>{{ title }}</span>
          <i
            href="javascript:;"
            class="el-icon-close modal-header-close"
            @click="$emit('cancel')"
          ></i>
        </div>
        <div class="modal-body">
          <slot name="body"> </slot>
        </div>
        <div class="modal-footer">
          <a
            href="javascript:;"
            class="btn "
            v-if="btnType == 1"
            @click="$emit('submit')"
            >{{ sureText }}
          </a>
          <a
            href="javascript:;"
            class="btn "
            v-if="btnType == 2"
            @click="$emit('cancel')"
            >{{ cancelText }}
          </a>
          <div class="btn-group" v-if="btnType == 3">
            <a href="javascript:;" class="btn " @click="$emit('submit')">
              {{ sureText }}
            </a>
            <a
              href="javascript:;"
              class="btn btn-default "
              @click="$emit('cancel')"
            >
              {{ cancelText }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { reactive, toRefs, onBeforeMount, onMounted } from "vue";
export default {
  name: "AdminModal",
  props: {
    // 弹框类型 small middle large
    // modalType: {
    //   type: String,
    //   default: "form",
    // },
    // 弹框标题
    title: String,
    //按钮类型： 1确定按钮，2取消按钮 3确定取消
    btnType: String,
    // 确定按钮
    sureText: {
      type: String,
      default: "确定",
    },
    // 取消按钮
    cancelText: {
      type: String,
      default: "取消",
    },
    // modal开闭状态
    showModal: {
      type: Boolean,
    },
  },
  setup(props) {
    const data = reactive({
      // modalType: props.modalType,
      title: props.title,
      btnType: props.btnType,
      sureText: props.sureText,
      cancelText: props.cancelText,
      showModal: props.cancelText,
    });
    onBeforeMount(() => {});
    onMounted(() => {});
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/mixin.scss";
@import "@/styles/modal.scss";
@import "@/styles/button.scss";
</style>

export var role = {
    //普通用户
    default: '0',
    // 应用管理员
    owner: '1',
    //后台管理员 GM
    abover: '2',
}

export var appStatus = {
    //审批中
    approving: 0,
    // 启用
    active: 1,
    // 下线
    disable: 2,
}

export var setTypes = {
    // 内容分类
    content: '1',
    // 应用分类
    app: '2',
}

<!-- 相关配置 -->
<template>
  <el-scrollbar>
    <!-- 页面顶部 -->
    <HeaderRow>
      <template #button>
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="add-btn"
            @click="handleAddCategory"
          >
            新增类型
          </el-button>
        </div>
      </template>
    </HeaderRow>

    <!-- 工具栏 -->
    <el-row>
      <div class="tooltip-wrap" ref="toolbarRef">
        <el-form :inline="true" :model="tableToolSearch">
          <el-form-item label="类别名称">
            <el-input
              v-model="tableToolSearch.categoryName"
              size="medium"
              placeholder="请输入类别名称"
              class="app-name"
            ></el-input>
          </el-form-item>

          <el-form-item label="操作人">
            <el-input
              v-model="tableToolSearch.userName"
              size="medium"
              placeholder="请输入操作人"
              class="app-class"
            ></el-input>
          </el-form-item>
          <el-form-item class="app-btn-group">
            <!-- <el-button type="primary" @click="onSubmit">查询</el-button> -->
            <el-button-group>
              <el-button
                type="primary"
                class="tooltip-btn search"
                @click="onSubmitSearch"
                >查询</el-button
              >
              <el-button
                type="default"
                class="tooltip-btn reset"
                @click="onSubmitReset"
                >重置</el-button
              >
            </el-button-group>
          </el-form-item>
        </el-form>
      </div>
    </el-row>

    <!-- tabs + table内容 -->
    <el-tabs
      ref="tabsRef"
      class="tabs-wrap"
      v-model="tabsActiveName"
      @tab-click="handleClickTabs"
    >
      <el-tab-pane
        :name="item.name"
        v-for="(item, index) in tabList"
        :key="index"
      >
        <template #label>
          <span>
            <i :class="item.icon"></i>
            {{ item.title }}
          </span>
        </template>
      </el-tab-pane>
      <BaseTable
        :tableHeight="tableHeight"
        :tableColumns="tableColumns"
        :tableData="tableData"
        :loading="tableLoading"
        @editConfig="handleEditConfig"
        @deleteConfig="handleDeleteConfig"
      >
        <template #empty>
          <div class="table-nodata">
            <img src="../../../assets/noDataBg.png" alt="" srcset="" />
            <a
              href="javascript:viod(0)"
              class="btn-add"
              @click="handleAddCategory"
            >
              新增类型
            </a>
          </div>
        </template>
      </BaseTable>
    </el-tabs>

    <!-- 删除 -->
    <AdminModal
      :showModal="showDelModal"
      btnType="3"
      sureText="确认删除"
      cnacel="取消"
      @cancel="showDelModal = false"
      @submit="submitDelConfig"
    >
      <template v-slot:body>
        <div class="body-wrap">
          <i class="el-icon-question"></i>
          <p>确定要删除此类别？</p>
          <span>类别删除后不可恢复</span>
        </div>
      </template>
    </AdminModal>
    <!-- 禁止删除 -->
    <AdminModal
      :showModal="showDelModalDisable"
      btnType="1"
      sureText="我知道了"
      @submit="showDelModalDisable = false"
    >
      <template v-slot:body>
        <div class="body-wrap">
          <i class="el-icon-warning"></i>
          <p>该配置类别正在使用中，不可删除！</p>
        </div>
      </template>
    </AdminModal>
    <!-- 新增类别 -->
    <el-drawer
      v-model="addConfDrawer"
      direction="rtl"
      size="33%"
      @close="confDrawerClose"
    >
      <p class="conf-drawer-title">新增类别</p>
      <el-form
        :model="formConfInfo"
        class="conf-drawer-form"
        ref="confAddFormRefs"
        :rules="confAddFormRules"
      >
        <el-form-item class="form-input conf-input-name" prop="name">
          <span class="tips"></span>
          <el-input
            v-model="formConfInfo.name"
            placeholder="请输入新的类别名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn-wrap">
        <BaseButton
          type="btn-default"
          size="btn-large"
          class="cancel-add-conf"
          @click="cancelAddConfig"
          >取消</BaseButton
        >
        <BaseButton type="btn" size="btn-large" @click="addConfigInfo"
          >确定</BaseButton
        >
      </div>
    </el-drawer>
    <!-- 编辑类别 -->
    <el-drawer
      v-model="editConfDrawer"
      direction="rtl"
      size="33%"
      @close="confDrawerClose"
    >
      <p class="conf-drawer-title">编辑类别</p>
      <el-form
        :model="formConfInfo"
        class="conf-drawer-form"
        ref="confEditFormRefs"
        :rules="confEditFormRules"
      >
        <el-form-item class="form-input conf-input-name" prop="name">
          <el-input
            v-model="formConfInfo.name"
            placeholder="请修改类别名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="conf-input-tips">
        <i class="el-icon-warning"></i>
        <p>类别名称修改后，之前使用该类别的名称都将更改</p>
      </div>
      <div class="btn-wrap">
        <BaseButton
          type="btn-default"
          size="btn-large"
          @click="cancelEditConfig"
          class="cancel-add-conf"
          >取消</BaseButton
        >
        <BaseButton type="btn" size="btn-large" @click="saveConfigInfo"
          >保存</BaseButton
        >
      </div>
    </el-drawer>
  </el-scrollbar>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  toRefs,
  onMounted,
  onUnmounted,
  onBeforeMount,
} from "vue";
import router from "@/router/index.js";
import {
  appSearch,
  appApproval,
  appEnable,
  appDisable,
  setType,
  setAllByType,
  setNewType,
  setDisableType,
  setSaveType,
} from "@/utils/api";
import { appStatus, role, setTypes } from "@/utils/constant";
import AdminModal from "@/views/Admin/components/AdminModal";
import BaseButton from "@/views/Admin/components/BaseButton";
import { ElMessage } from "element-plus";
import HeaderRow from "@/views/Admin/components/HeaderRow";
import BaseTable from "../components/BaseTable";
export default {
  components: {
    AdminModal,
    BaseButton,
    HeaderRow,
    BaseTable,
  },
  setup: function() {
    const confAddFormRefs = ref(null);
    const confEditFormRefs = ref(null);
    const toolbarRef = ref(null);
    // 获取所有类型
    // setAllByType({}).then(res=>{
    //   console.log(res)
    // })
    const state = reactive({
      // 动态分类列表
      configList: [],
      // 应用分类列表
      appList: [],
      // 工具栏表单
      tableToolSearch: {
        categoryName: "",
        userName: "",
      },
      tabList: [],
      // 表格数据
      tableLoading: false, // table 加载loading
      tableData: [],
      tableColumns: [
        {
          prop: "id",
          label: "id",
          fixed: true,
        },
        {
          prop: "name",
          label: "类别名称",
        },
        {
          prop: "creatorName",
          label: "操作人",
        },
        {
          prop: "updaterName",
          label: "更新人",
        },
        {
          prop: "created",
          label: "添加时间",
        },
        {
          prop: "updated",
          label: "更新时间",
        },
        {
          label: "操作",
          minWidth: "260px",
          operation: [
            { name: "编辑", clickFun: "editConfig", icon: "el-icon-edit" },
            { name: "删除", clickFun: "deleteConfig", icon: "el-icon-delete" },
          ],
        },
      ],
      showDelModal: false, // 删除当前应用 modal
      showDelModalDisable: false, // 不可删除当前应用 modal
      tableHeight: 0, // table高度
      tabsActiveName: "content", // tabs默认激活页
      addConfDrawer: false, // 新增类别 Drawer
      editConfDrawer: false, // 编辑类别 Drawer
      // 新增类别form
      formConfInfo: {
        name: "",
        id: "",
      },
      // 新增类别 form rules
      confAddFormRules: {
        name: [
          { required: true, message: "请输入类别名称", trigger: "blur" },
          { max: 10, message: "长度在10个字符以内", trigger: "blur" },
        ],
      },
      // 编辑类别 form rules
      confEditFormRules: {
        name: [
          { required: true, message: "请输入类别名称", trigger: "blur" },
          { max: 10, message: "长度在10个字符以内", trigger: "blur" },
        ],
      },
      // 工具栏查询
      onSubmitSearch: () => {
        const { tableToolSearch, tableData, tabsActiveName } = state;
        console.log(
          "onSubmitSearch!",
          tableToolSearch,
          tableData,
          tabsActiveName
        );
        if (!tableToolSearch.categoryName && !tableToolSearch.userName) {
          ElMessage.warning({
            type: "warning",
            message: "请输入查询条件",
          });
        } else {
          const tempList = JSON.parse(JSON.stringify(tableData)).filter(
            (item) => {
              return (
                item.name.includes(tableToolSearch.categoryName) &&
                item.creatorName.includes(tableToolSearch.userName)
              );
            }
          );
          state.tableData = tempList;
        }
      },
      // 工具栏重置
      onSubmitReset: () => {
        console.log("onSubmitReset!", state.tableToolSearch);
        state.tableToolSearch = {
          categoryName: "",
          userName: "",
        };
        // 模拟清空
        state.findMyConfList();
      },
      templateParams: {
        base: {
          created: new Date().getTime(),
          creatorName: "",
          creatorNo: "",
          updated: new Date().getTime(),
          updaterName: "",
          updaterNo: "",
        },
        id: 0,
        name: "",
        type: "",
      },
      // 提交新增类别
      addConfigInfo() {
        let { templateParams: temp } = state;
        temp.type = setTypes[state.tabsActiveName];
        temp.name = state.formConfInfo.name;
        confAddFormRefs.value.validate((valid) => {
          if (valid) {
            setNewType(temp)
              .then((res) => {
                console.log(res);
                ElMessage.success({
                  type: "info",
                  message: `添加成功`,
                  onClose: () => {
                    confAddFormRefs.value.resetFields();
                    state.addConfDrawer = false;
                    state.findMyConfList();
                  },
                });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            return false;
          }
        });
      },
      // 保存修改
      saveConfigInfo() {
        let {
          templateParams: temp,
          formConfInfo: { id, name },
          tabsActiveName,
        } = state;
        temp.type = setTypes[tabsActiveName];
        temp.name = name;
        console.log(temp);
        confEditFormRefs.value.validate((valid) => {
          if (valid) {
            setSaveType(id, temp)
              .then((res) => {
                confEditFormRefs.value.resetFields();
                state.editConfDrawer = false;
                state.findMyConfList();
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            return false;
          }
        });
      },
      // 新增类别
      handleAddCategory() {
        state.addConfDrawer = true;
        state.formConfInfo = {
          name: "",
          id: "",
        };
      },
      // 取消新增
      cancelAddConfig() {
        confAddFormRefs.value.resetFields();
        state.addConfDrawer = false;
      },
      // 取消保存
      cancelEditConfig() {
        confEditFormRefs.value.resetFields();
        state.editConfDrawer = false;
      },
      // 抽屉关闭事件，清空表单内容
      confDrawerClose() {
        const { tabsActiveName, editConfDrawer, addConfDrawer } = state;
        if (addConfDrawer) {
          confAddFormRefs.value.resetFields();
        }
        if (editConfDrawer) {
          confEditFormRefs.value.resetFields();
        }
      },
      // table编辑按钮
      handleEditConfig(rowData) {
        state.editConfDrawer = true;
        state.formConfInfo.name = rowData.name;
        state.formConfInfo.id = rowData.id;
      },
      // table删除按钮
      handleDeleteConfig(rowData) {
        state.showDelModal = true;
        state.formConfInfo.name = rowData.name;
        state.formConfInfo.id = rowData.id;
      },
      // modal点击删除
      async submitDelConfig() {
        try {
          await setDisableType(state.formConfInfo.id);
          state.showDelModal = false;
          state.findMyConfList();
        } catch (error) {
          state.showDelModal = false;
        }
      },
      // 初始化tab
      initTabList() {
        console.log("初始化tab", setTypes);
        const keys = Object.keys(setTypes);
        const values = Object.values(setType);
        state.tabList = [
          {
            name: "content",
            title: "动态分类",
            icon: "el-icon-date",
          },
          {
            name: "app",
            title: "应用分类",
            icon: "el-icon-date",
          },
        ];
        console.log(JSON.stringify(state.tabList));
      },
      // tab切换事件
      handleClickTabs(tab, event) {
        console.log(tab, event);
        state.findMyConfList();
      },
      // 初始化table数据
      findMyConfList() {
        /* 
          {
            appkey: "",
            base: {
              created: "2020-01-01 10:00:00",
              creatorName: "张无忌",
              creatorNo: 12345667,
              updated: "2020-01-01 10:00:00",
              updaterName: "Admin",
              updaterNo: 12345667,
            },
            description:
              "这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字",
            icon: "http://a.com/icon?id=xxxxxxx",
            level: 1,
            name: "用户中心",
            page: "http://a.com/ticket/",
            secret: "qweidi",
            status: 1,
          },
        */
        // state.configList = state.initTestList();
        state.tableLoading = true;
        setAllByType(setTypes[state.tabsActiveName])
          .then((res) => {
            // 平铺base,提供数据给BaseTable组件
            state.tableData = res.data.map((v) => ({ ...v, ...v.base }));
            // state.tableData = [];
            state.tableLoading = false;
          })
          .catch((err) => {
            console.log(err);
            state.tableLoading = false;
          });
      },
      // 动态获取table高度
      getHeight() {
        const toolbarHeight = toolbarRef.value.offsetHeight;
        state.tableHeight = window.innerHeight - toolbarHeight - 260;
      },
    });
    onBeforeMount(() => {
      state.initTabList();
      state.findMyConfList();
    });
    onMounted(() => {
      state.getHeight();
      window.addEventListener("resize", state.getHeight);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", state.getHeight);
    });
    const refData = toRefs(state);

    return {
      confAddFormRefs,
      confEditFormRefs,
      toolbarRef,
      ...refData,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/mixin.scss";
@import "@/styles/button.scss";
@import "@/styles/element-ui.scss";
// modal body content
.body-wrap {
  height: 100%;
  @include flex(column, center);
  &::v-deep(.el-icon-question),
  .el-icon-warning {
    font-size: 65px;
    color: #ee3b40;
  }
  p {
    font-size: 18px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #595959;
    line-height: 23px;
    margin: 23px auto 13px;
  }
  span {
    font-size: 14px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #9e9e9e;
    line-height: 23px;
  }
}

.row-header {
  margin-bottom: 20px;
}
// tooltip--工具栏
.tooltip-wrap {
  width: 100%;
  height: 123px;
  border-bottom: 1px solid #dedede;
  background: #ffffff;
  // border-bottom: 1px solid #dedede;
  // display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 36px;
  background-color: #fff;
  .el-form {
    display: flex;
    align-items: center;
  }
  &::v-deep(.el-form--inline .el-form-item) {
    display: flex;
    align-items: center;
  }
  &::v-deep(.el-form-item__label) {
    font-size: 18px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #262626;
    line-height: 50px;
  }
  //工具栏--form表单input
  .app-name,
  .app-admin,
  .app-class {
    margin-right: 70px;
    width: 217px;
    height: 50px;
    border-color: #dfe3e8;
    ::v-deep(.el-input__inner) {
      width: 217px;
      height: 50px;
      &::placeholder {
        font-size: 16px;
        font-family: SourceNormal;
        font-weight: 400;
        color: #c9cbcf;
      }
    }
  }
  //工具栏--form表单input
  .app-category {
    height: 50px;
    // 下拉框
    &::v-deep(.el-select) {
      width: 190px;
      height: 50px;
      background: #ffffff;
      // 下拉icon
      .el-input .el-select__caret {
        color: #333;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  //工具栏--form表单button
  .app-btn-group {
    margin-left: 64px;
    // 按钮组间距
    &::v-deep(.el-form-item__content) {
      line-height: 0;
    }
    // 查询重置按钮组
    .tooltip-btn {
      width: 100px;
      height: 50px;
      min-height: 30px;
      padding: 0;
      font-family: SourceNormal;
      font-weight: 400;
      font-size: 18px;
      & ::v-deep .el-form-item__content {
        line-height: 50px;
      }
      &.search {
        background: #227fff;
        color: #ffffff;
      }
      &.reset {
        // background: #f4f4f4;
        background: #fff;
        color: #7d7d7d;
        &:hover {
          color: #227fff;
        }
      }
    }
  }
}
// table无数据
.table-nodata {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  img {
    width: 412px;
    height: 235px;
  }
}
// 抽屉--新增类型
::v-deep(.el-drawer) {
  .conf-drawer-form {
    .conf-input-name {
      width: 532px;
      height: 61px;
      margin: 0 auto;
      .el-form-item__content {
        width: 532px;
        height: 61px;
        .el-input__inner {
          width: 532px;
          height: 61px;
          background: #fff;
          border: none;
          border-radius: 0;
          border-bottom: 2px solid #227fff;
          padding: 0;
          &::placeholder {
            font-size: 20px;
            font-family: SourceRegular;
            font-weight: 400;
            color: #989898;
          }
        }
      }
    }
  }
  // 抽屉title
  .conf-drawer-title {
    font-size: 30px;
    font-family: SourceRegular;
    font-weight: bold;
    color: #303235;
    margin: 0 0 40px 48px;
  }
  // 抽屉input提示
  .conf-input-tips {
    display: flex;
    width: 100%;
    padding-top: 50px;
    .el-icon-warning {
      font-size: 25px;
      color: #ee3b40;
      margin: 0 10px 0 47px;
    }
    p {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #989898;
      line-height: 23px;
    }
  }
  // 按钮组
  .btn-wrap {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 88px;
    justify-content: center;
    .cancel-add-conf {
      border: 1px solid transparent;
      background: #fff;
      &:hover {
        color: #227fff;
      }
    }
    button {
      margin: 0 20px;
    }
  }
}

// tab切换
.tabs-wrap {
  width: 100%;
  &::v-deep(.el-tabs__nav-wrap) {
    background: #fff;
    padding-left: 30px;
    height: 100px;
    &::after {
      height: 1px;
    }
    .el-tabs__nav-scroll {
      height: 100%;
      .el-tabs__nav {
        transform: translateX(60px);
        height: 100%;
        .el-tabs__item {
          font-size: 20px;
          font-family: SourceRegular;
          font-weight: bold;
          line-height: 100px;
        }
      }
    }
  }
}

// 气泡提示
.el-popover .el-row {
  line-height: 30px;
  margin: 3px;
}

.el-popover .el-row .el-tag {
  width: 80px;
  margin-right: 3px;
}

.el-popover .el-row .tooltip {
  width: 260px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
</style>
